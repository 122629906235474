.GrowYourBussionWrapper {
    background-color: var(--GrowYourBussionWrapper-bgColor);
}

.GrowYourBussion-wrapper {
    padding: 30px 20px;
    margin: 0 -20px;
}

.GrowYourBussion-wrapper .left {
    flex: 0 1 555px;

}

.GrowYourBussion-wrapper .left img {
    width: 100%;
}

.GrowYourBussion-wrapper .right {
    padding-top: 40px;
    flex: 0 1 424px;
    align-self: center;

}

.GrowYourBussion-wrapper .right h2 {
    margin-bottom: 10px;
}


.GrowYourBussion-wrapper .right p.h3 {
    font-size: 20px;
    margin-bottom: 30px;
}

.GrowYourBussion-wrapper .right p {
    font-size: 15px;
}

.GrowYourBussion-wrapper .middle-wrapper {
    padding: 30px 0;
    text-align: center;
    gap: 50;
    margin-top: 140px;
}

.GrowYourBussion-wrapper .middle-wrapper .heading-2 {
    margin: 30px 0;
}

.GrowYourBussion-wrapper .middle-wrapper .box {
    padding: 0 30px 30px;
    width: 282px;
    height: 228px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 20px #49494921;
border-radius: 10px;
}

.GrowYourBussion-wrapper .middle-wrapper .box.box-4 {
    border-right: none;
}

.GrowYourBussion-wrapper .middle-wrapper .img {
    width: 50px;
    height: 50px;
    background-color: var(--GrowYourBussion-wrapper-img-bgColor);
    border-radius: 50%;
    margin: auto;
    margin: 30px auto 20px;
}
.GrowYourBussion-wrapper .middle-wrapper .img img{
     width: 25px;
}

.GrowYourBussion-wrapper .middle-wrapper h2 {
    margin: 0 0 10px;
   font-size: 24px;
}
.GrowYourBussion-wrapper .middle-wrapper p {

   font-size: 14px;
}

.GrowYourBussion-wrapper .middle-wrapper-top{
    width: 100%;
    height: 414px;
    background-image: url('../../assets/images/bg-8.png');
    background-size: cover;
    padding: 60px;
   color: #fff;
   justify-content: space-between;
  
}
.GrowYourBussion-wrapper .middle-wrapper-top .left{
    max-width: 550px;
  
}
.GrowYourBussion-wrapper .middle-wrapper-top .right{
    max-width: 270px;
    margin-top: -370px;
    margin-right: 94px;
  
}

.GrowYourBussion-wrapper .middle-wrapper-top .right img{
    width: 100%;

}
.GrowYourBussion-wrapper .card-wrapper{
    margin: -100px 80px 0;
}

@media only screen and (max-width: 767px) {
    .GrowYourBussion-wrapper {
        padding-bottom: 20px;
    }

    .GrowYourBussion-wrapper .top-wrapper {
        flex-direction: column-reverse;
    }

    .GrowYourBussion-wrapper .top-wrapper>div {
        flex: 0 1 auto;
    }

    .GrowYourBussion-wrapper .middle-wrapper .center {
        flex-wrap: wrap;
        gap: 15px;
    }

    .GrowYourBussion-wrapper .middle-wrapper .box {
        width: 44%;
        border: none;
    }
}

@media only screen and (max-width: 480px) {
    .GrowYourBussion-wrapper .middle-wrapper .box {
        width: 90%;
        border: none;
    }
}